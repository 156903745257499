<template>
  <div class="v-application__wrap">
    <div class="d-flex justify-center align-center text-center h-100">
      <div>
        <img src="@/assets/img/errorimg.svg" width="500" alt="404" />
        <h1 class="text-h1 pt-3">{{ $t("lables.opps") }}</h1>
        <h4 class="text-h4 my-8">
          {{ $t("lables.pageNotFound") }}
        </h4>
        <base-button
         @click="goHome"
          className="v-btn v-btn--flat v-theme--BLUE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-elevated mb-4"
        >
          {{ $t("lables.goHome") }}
          
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "404",
  components: { BaseButton },
  data() {
    return {};
  },
  methods: {
    goHome(){
        this.$router.push({name:"Home"})
    }
  },

  mounted() {},
};
</script>
<style></style>
